import "./App.css"
import React from "react"
import TableComponent from "./TableComponent"
import StatusComponent from "./StatusComponent"
import StatusComponentMobile from "./StatusComponentMobile"
import { ThemeProvider, createTheme } from "@mui/material/styles"

import injectedModule from "@web3-onboard/injected-wallets"
//import ledgerModule from "@web3-onboard/ledger"
//import walletConnectModule from "@web3-onboard/walletconnect"
import trezorModule from "@web3-onboard/trezor"
import gnosisModule from "@web3-onboard/gnosis"
import coinbaseWalletModule from "@web3-onboard/coinbase"
import frameModule from "@web3-onboard/frame"
import { init } from "@web3-onboard/react"
import { Buffer } from "buffer"
import ReactModal from "react-modal"
import { blessLogo } from "./blessLogo"

window.Buffer = window.Buffer || Buffer
ReactModal.setAppElement("#root")

// =====================================================
// Setup Web3-onboard
// vvvvvvvvvvvvvvvvvv
// =====================================================
const injected = injectedModule()

// const wcV2InitOptions = {
//   /**
//    * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
//    */
//   projectId: "2718449b503547d57e51a632e1243df6",
//   /**
//    * Chains required to be supported by all wallets connecting to your DApp
//    */
//   requiredChains: [1, 42161, 10, 137, 5, 11155111, 421613, 420, 80001],
//   /**
//    * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
//    * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
//    * To connect with WalletConnect
//    */
//   dappUrl: "https://arrng.io",
// }
// const walletConnect = walletConnectModule(wcV1InitOptions)
// const ledger = ledgerModule()
const trezor = trezorModule({
  email: 'arr@arrng.io"',
  appUrl: "https://www.arrng.io/",
})
const gnosis = gnosisModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const frame = frameModule()
const appMetadata = {
  name: "connected to arrng",
  icon: "/icoMain40.svg",

  description: "easy on-chain randonmess",
}
const theme = {
  "--w3o-background-color": "#3d3d3d",
  "--w3o-foreground-color": "black",
  "--w3o-text-color": "#fff",
  "--w3o-border-color": "#e7d6bd",
  "--w3o-action-color": "#e7d6bd",
  "--w3o-border-radius": "10px",
}
const rpc = {
  rpc_1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpc_42161: "https://arb1.arbitrum.io/rpc",
  rpc_10: "https://optimism.publicnode.com",
  rpc_137: "https://endpoints.omniatech.io/v1/matic/mainnet/public",
  rpc_5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpc_11155111: "https://ethereum-sepolia.blockpi.network/v1/rpc/public",
  rpc_421613: "https://goerli-rollup.arbitrum.io/rpc",
  rpc_420: "https://endpoints.omniatech.io/v1/op/goerli/public",
  rpc_80001: "https://endpoints.omniatech.io/v1/matic/mumbai/public",
  rpc_168587773:
    "https://rpc.ankr.com/blast_testnet_sepolia/e10a0202afd5e96cba442ff946ee5f36be73ca0112346803dd6d26026a69fde6",
  rpc_11145513: "https://blessnet-sepolia-testnet.rpc.caldera.xyz/http",
  rpc_45513: "https://blessnet.calderachain.xyz/http",
}

init({
  theme,
  wallets: [
    injected,
    //ledger,
    //walletConnect,
    trezor,
    gnosis,
    coinbaseWalletSdk,
    frame,
  ],
  chains: [
    {
      id: 1,
      token: "ETH",
      label: "Eth Mainnet",
      rpcUrl: rpc["rpc_1"],
    },
    {
      id: 42161,
      token: "ETH",
      label: "Arbitrum One",
      rpcUrl: rpc["rpc_42161"],
    },
    {
      id: 10,
      token: "ETH",
      label: "Optimism",
      rpcUrl: rpc["rpc_10"],
    },
    {
      id: 137,
      token: "MATIC",
      label: "Polygon",
      rpcUrl: rpc["rpc_137"],
    },
    {
      id: 11155111,
      token: "ETH",
      label: "Eth Sepolia",
      rpcUrl: rpc["rpc_11155111"],
    },
    {
      id: 421613,
      token: "ETH",
      label: "Arbitrum Goerli",
      rpcUrl: rpc["rpc_421613"],
    },
    {
      id: 420,
      token: "ETH",
      label: "Optimism Goerli",
      rpcUrl: rpc["rpc_420"],
    },
    {
      id: 80001,
      token: "MATIC",
      label: "Polygon Mumbai",
      rpcUrl: rpc["rpc_80001"],
    },
    {
      id: 168587773,
      token: "ETH",
      label: "Blast Sepolia",
      rpcUrl: rpc["rpc_168587773"],
    },
    {
      id: 11145513,
      token: "BLESS",
      label: "Bless Sepolia",
      rpcUrl: rpc["rpc_11145513"],
      icon: blessLogo,
    },
    {
      id: 45513,
      token: "BLESS",
      label: "Blessnet",
      rpcUrl: rpc["rpc_45513"],
      icon: blessLogo,
    },
  ],
  appMetadata,
})
// =====================================================
// ^^^^^^^^^^^^^^^^^^
// Setup Web3-onboard
// =====================================================

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
})

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          background: "black",
        }}
      >
        <div className="HeroBanner">
          <a
            href="#"
            onClick={() => window.location.reload()} // Reload the current page when clicked
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src="/ico1.svg" alt="arrngDie" style={{ height: "25px" }} />
          </a>
          arrscan.io
          <a
            href="https://arrng.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src="/ico0.svg" alt="arrngDie" style={{ height: "25px" }} />
          </a>
        </div>
        <div style={{ flex: 1, overflowY: "auto" }}>
          <TableComponent />
        </div>

        <div>
          {/* Render MobileComponent on mobile devices */}
          <div className="mobile">
            <StatusComponentMobile />
          </div>

          {/* Render DesktopComponent on desktop devices */}
          <div className="desktop">
            <StatusComponent />
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
